import React from 'react';
import '../../App.css';
import GalleryCarousel from '../GalleryCarousel';

function Gallery() {
    return(
        <>
        <GalleryCarousel/>
        </>
    )
}

export default Gallery;