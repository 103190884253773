import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import '../Cards.css';

function Stories() {
    return(
        <>
        <Cards/>
        </>
    )
}

export default Stories;